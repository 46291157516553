import { MenuItemsType, DexIcon, FarmsIcon, StakeIcon, AddLiquidityIcon } from 'packages/uikit'
import { ContextApi } from 'contexts/Localization/types'
// import { nftsBaseUrl } from 'views/Nft/market/constants'
// import { perpLangMap } from 'utils/getPerpetualLanguageCode'
import { DropdownMenuItems, DescribeMenuItem } from 'packages/uikit/src/components/DropdownMenu/types'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean } & {
  items?: ConfigMenuDropDownItemsType[]
  describe?: DescribeMenuItem
}

const config: (t: ContextApi['t'], languageCode?: string) => ConfigMenuItemsType[] = (t, languageCode) => [
  {
    label: t('Swap'),
    icon: DexIcon,
    fillIcon: DexIcon,
    href: '/swap',
    showItemsOnMobile: false,
    items: [],
  },
  {
    label: t('Pools'),
    href: '/liquidity/all-pools',
    // href: '/#',
    icon: FarmsIcon,
    fillIcon: FarmsIcon,
    showItemsOnMobile: false,
    commingSoon: false,
    items: [
      {
        label: t('Add Liquidity'),
        href: '/add',
        icon: AddLiquidityIcon,
      },
      {
        label: t('Farms'),
        // href: '/farms',
        href: '/#',
        icon: FarmsIcon,
      },
    ],
    describe: {
      icon: FarmsIcon,
      fillIcon: FarmsIcon,
      title: 'Farms',
      description: 'Stake LP tokens to earn.',
    },
  },
  {
    label: t('Stake'),
    icon: StakeIcon,
    fillIcon: StakeIcon,
    // href: '/stake',
    href: '/#',
    showItemsOnMobile: false,
    commingSoon: false,
    items: [],
  },
  // {
  //   label: t('Leaderboard'),
  //   href: '/leaderboard',
  //   // href: '/#',
  //   icon: AirdropIcon,
  //   fillIcon: AirdropIcon,
  //   showItemsOnMobile: false,
  //   commingSoon: false,
  //   items: [],
  //   describe: {
  //     icon: AirdropIcon,
  //     fillIcon: AirdropIcon,
  //     title: 'Leaderboard',
  //     description: 'Point Leaderboard',
  //   },
  // },
  // {
  //   label: t('Mint'),
  //   // href: '/mint',
  //   href: '/#',
  //   icon: MintIcon,
  //   fillIcon: MintIcon,
  //   showItemsOnMobile: false,
  //   commingSoon: true,
  //   items: [],
  //   describe: {
  //     icon: MintIcon,
  //     fillIcon: MintIcon,
  //     title: 'Mint',
  //     description: 'Mint NFTs to Grab Airdrop',
  //   },
  // },
  // {
  //   label: t('Launch'),
  //   // href: '/launch',
  //   href: '/#',
  //   icon: LaunchIcon,
  //   fillIcon: LaunchIcon,
  //   showItemsOnMobile: false,
  //   commingSoon: true,
  //   items: [],
  //   describe: {
  //     icon: LaunchIcon,
  //     fillIcon: LaunchIcon,
  //     title: 'Launch',
  //     description: 'Early Access to Hidden Gems',
  //   },
  // },
]

export default config
