import { ChainId, Token } from '@sonefi-dex/sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { CHAIN_ID } from './networks'
import { SerializedToken } from './types'
import addresses from './addresses.json'

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

const defineTokens = <T extends TokenList>(t: T) => t

export const mainnetTokens = {
  eth: new Token(MAINNET, addresses[MAINNET].WETH, 18, 'ETH', 'Ether', 'https://sone.finance'),
  cake: new Token(MAINNET, addresses[MAINNET].PlatformToken, 18, 'SONE', 'SoneFinance', 'https://sone.finance'),
  usdt: new Token(
    MAINNET,
    '0x3a337a6ada9d885b6ad95ec48f9b75f197b5ae35',
    6,
    'USDT',
    'Tether USD',
    'https://sone.finance/',
  ),
  weth: new Token(MAINNET, addresses[MAINNET].WETH, 18, 'WETH', 'Wrapped ETH', 'https://sone.finance'),
  busd: new Token(
    MAINNET,
    '0x3a337a6ada9d885b6ad95ec48f9b75f197b5ae35',
    6,
    'USDT',
    'Tether USDT',
    'https://sone.finance',
  ),
  usdc: new Token(MAINNET, '0xba9986d2381edf1da03b0b9c1f8b00dc4aacc369', 6, 'USDC', 'USDC.e', 'https://sone.finance'),
  syrup: new Token(MAINNET, addresses[MAINNET].PlatformToken, 18, 'SONE', 'SoneFinance', 'https://sone.finance'),
}

export const testnetTokens = {
  cake: new Token(TESTNET, addresses[TESTNET].PlatformToken, 18, 'SONE', 'SoneFinance', 'https://sone.finance'),
  weth: new Token(TESTNET, addresses[TESTNET].WETH, 18, 'WETH', 'Wrapped ETH', 'https://sone.finance'),
  busd: new Token(
    TESTNET,
    '0x95512957E31cCE312ac2e6407e562228d3BC331b',
    18,
    'BUSD',
    'Taiko BUSD',
    'https://sone.finance',
  ),
  usdc: new Token(
    TESTNET,
    '0x8D299B74C28961738E20dB72478e13F2e138e8B5',
    18,
    'USDC',
    'USD coin',
    'https://sone.finance',
  ),
}

const tokens = () => {
  const chainId = CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    return Object.keys(testnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] }
    }, {} as typeof testnetTokens)
    // return Object.keys(mainnetTokens).reduce((accum, key) => {
    //   return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    // }, {} as typeof testnetTokens & typeof mainnetTokens)
  }

  return mainnetTokens
}

const unserializedTokens = tokens()

type SerializedTokenList = Record<keyof typeof unserializedTokens, SerializedToken>

export const serializeTokens = () => {
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {} as SerializedTokenList)

  return serializedTokens as any
}

export default unserializedTokens
