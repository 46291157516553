import { BigNumber } from '@ethersproject/bignumber'
import Trans from 'components/Trans'
import { VaultKey } from 'state/types'
import { ChainId } from '@sonefi-dex/sdk'
import { CHAIN_ID } from './networks'
import { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const chainId = parseInt(CHAIN_ID, 10)
const { MAINNET } = ChainId

const serializedTokens = serializeTokens()

export const MAX_LOCK_DURATION = 31536000
export const UNLOCK_FREE_DURATION = 604800
export const ONE_WEEK_DEFAULT = 604800
export const BOOST_WEIGHT = BigNumber.from('10000000000000')
export const DURATION_FACTOR = BigNumber.from('31536000')

export const vaultPoolConfig = {
  [VaultKey.CakeVaultV1]: {
    name: <Trans>Auto SONE</Trans>,
    description: <Trans>Automatic restaking</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 1000000,
    tokenImage: {
      primarySrc: `/images/toggle/tether.png`,
      secondarySrc: '/images/tokens/stake-auto.svg',
    },
  },
  [VaultKey.CakeVault]: {
    name: <Trans>Stake SONE</Trans>,
    description: <Trans>Earn SONE - Core pool</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 1000000,
    tokenImage: {
      primarySrc: `/images/toggle/tether.png`,
      secondarySrc: '/images/tokens/stake-auto.svg',
    },
  },
} as const

const pools: SerializedPoolConfig[] =
  chainId === MAINNET
    ? [
        // {
        //   sousId: 0,
        //   stakingToken: serializedTokens.cake,
        //   earningToken: serializedTokens.cake,
        //   contractAddress: {
        //     1868: '0xf2658ee288e92789e6c3f4f33cb1c0eaaef338f6',
        //     1946: '',
        //   },
        //   poolCategory: PoolCategory.CORE,
        //   harvest: true,
        //   tokenPerBlock: '3.25',
        //   sortOrder: 0,
        //   isFinished: false,
        // },
        // {
        //   sousId: 1,
        //   stakingToken: serializedTokens.weth,
        //   earningToken: serializedTokens.cake,
        //   contractAddress: {
        //     1868: '0x3D358096Cdd9B2c81f49E827fA1b53a579b5881e',
        //     1946: '',
        //   },
        //   poolCategory: PoolCategory.CORE,
        //   harvest: true,
        //   tokenPerBlock: '0.05144',
        //   sortOrder: 1,
        //   isFinished: false,
        // },
        // {
        //   sousId: 2,
        //   stakingToken: serializedTokens.taiko,
        //   earningToken: serializedTokens.taiko,
        //   contractAddress: {
        //     1868: '0x562c179d91E4C6c78c5500e909971a113367C3dc',
        //     1946: '',
        //   },
        //   poolCategory: PoolCategory.CORE,
        //   harvest: true,
        //   tokenPerBlock: '0.00071444902',
        //   sortOrder: 2,
        //   isFinished: false,
        // },
      ]
    : [
        {
          sousId: 0,
          stakingToken: serializedTokens.cake,
          earningToken: serializedTokens.cake,
          contractAddress: {
            1868: '',
            1946: '0x63aC402FA2322255422925e2Bb05911644c850c1',
          },
          poolCategory: PoolCategory.CORE,
          harvest: true,
          tokenPerBlock: '1',
          sortOrder: 1,
          isFinished: false,
        },
        {
          sousId: 1,
          stakingToken: serializedTokens.cake,
          earningToken: serializedTokens.usdc,
          contractAddress: {
            1868: '',
            1946: '0x306EAd98B78D8aC7986528C03b708782f7A956cA',
          },
          poolCategory: PoolCategory.CORE,
          harvest: true,
          tokenPerBlock: '1',
          sortOrder: 1,
          isFinished: true,
        },
        {
          sousId: 2,
          stakingToken: serializedTokens.usdc,
          earningToken: serializedTokens.cake,
          contractAddress: {
            1868: '',
            1946: '0x28200E6C1e87080a6ef45032B3ce6e868F2fF5A2',
          },
          poolCategory: PoolCategory.CORE,
          harvest: true,
          tokenPerBlock: '1',
          sortOrder: 2,
          isFinished: true,
        },
        {
          sousId: 3,
          stakingToken: serializedTokens.weth,
          earningToken: serializedTokens.cake,
          contractAddress: {
            1868: '',
            1946: '0x0A2f3A62A40114585DB7968c6cEc5b6f9F6b97D6',
          },
          poolCategory: PoolCategory.CORE,
          harvest: true,
          tokenPerBlock: '1',
          sortOrder: 3,
          isFinished: true,
        },
        {
          sousId: 4,
          stakingToken: serializedTokens.usdc,
          earningToken: serializedTokens.cake,
          contractAddress: {
            1868: '',
            1946: '0xc967f59d83BE8B4a2c8835b1f9563176e0da4925',
          },
          poolCategory: PoolCategory.CORE,
          harvest: true,
          tokenPerBlock: '1',
          sortOrder: 4,
          isFinished: false,
        },
        {
          sousId: 5,
          stakingToken: serializedTokens.weth,
          earningToken: serializedTokens.cake,
          contractAddress: {
            1868: '',
            1946: '0xcE4DF8e0eC67533fF0A841A15e50fc19490A2434',
          },
          poolCategory: PoolCategory.CORE,
          harvest: true,
          tokenPerBlock: '1',
          sortOrder: 5,
          isFinished: false,
        },
        {
          sousId: 6,
          stakingToken: serializedTokens.cake,
          earningToken: serializedTokens.emmet,
          contractAddress: {
            1868: '',
            1946: '0x3D45750782B7c5B7a9b60ac463811E970E55071D',
          },
          poolCategory: PoolCategory.CORE,
          harvest: true,
          tokenPerBlock: '0.00001736111',
          sortOrder: 6,
          isFinished: true,
        },
        {
          sousId: 7,
          stakingToken: serializedTokens.cake,
          earningToken: serializedTokens.emmet,
          contractAddress: {
            1868: '',
            1946: '0xA0ef0C45999C42950ED8c860863154b46C0230ab',
          },
          poolCategory: PoolCategory.CORE,
          harvest: true,
          tokenPerBlock: '0.0003858024691',
          sortOrder: 7,
          isFinished: false,
        },
        {
          sousId: 8,
          stakingToken: serializedTokens.cake,
          earningToken: serializedTokens.zns,
          contractAddress: {
            1868: '',
            1946: '0x15D686b95E2F3b1bf4a766004e5a9509842a477C',
          },
          poolCategory: PoolCategory.CORE,
          harvest: true,
          tokenPerBlock: '0.3858024691',
          sortOrder: 8,
          isFinished: false,
        },
        {
          sousId: 9,
          stakingToken: serializedTokens.cake,
          earningToken: serializedTokens.weth,
          contractAddress: {
            1868: '',
            1946: '0xBfC0DA565cAFde5e50aE27D05B2be62472Dd6008',
          },
          poolCategory: PoolCategory.CORE,
          harvest: true,
          tokenPerBlock: '0.00019',
          sortOrder: 9,
          isFinished: false,
        },
      ]
export default pools
