import { ChainId } from '@sonefi-dex/sdk'
import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'
import { CHAIN_ID } from './networks'

const serializedTokens = serializeTokens()

const chainId = parseInt(CHAIN_ID, 10)
const { MAINNET } = ChainId

export const VLX_WAG_FARM_PID = chainId === MAINNET ? 1 : 1 // WAG-VLX (2)
export const VLX_USDT_FARM_PID = chainId === MAINNET ? 1 : 1 // BUSD-VLX (3)

const farms: SerializedFarmConfig[] =
  chainId === MAINNET
    ? [
        // {
        //   pid: 0,
        //   v1pid: 0,
        //   lpSymbol: 'SoneFinanceDex',
        //   lpAddresses: {
        //     1868: '0xdad398Df5Eec85b920d14AF20B64823747387090',
        //     167009: '',
        //   },
        //   token: serializedTokens.syrup,
        //   quoteToken: serializedTokens.weth,
        // },
        // {
        //   pid: 1,
        //   v1pid: 1,
        //   lpSymbol: 'SONE-ETH LP',
        //   lpAddresses: {
        //     1868: '0xa282af36E0864a90edc9cF893E0400CF55F362Aa',
        //     167009: '',
        //   },
        //   token: serializedTokens.cake,
        //   quoteToken: serializedTokens.weth,
        // },
        // {
        //   pid: 2,
        //   v1pid: 2,
        //   lpSymbol: 'ETH-USDC LP',
        //   lpAddresses: {
        //     1868: '0x776161d239dAA38FD38DE01aE8C27d007eF89D2E',
        //     167009: '',
        //   },
        //   token: serializedTokens.usdc,
        //   quoteToken: serializedTokens.weth,
        // },
        // {
        //   pid: 3,
        //   v1pid: 3,
        //   lpSymbol: 'SNF-ETH LP',
        //   lpAddresses: {
        //     1868: '0xe9Ae1bC7A7454026E709Acc409de19cEB202e35f',
        //     167009: '',
        //   },
        //   token: serializedTokens.taiko,
        //   quoteToken: serializedTokens.weth,
        // },
      ]
    : [
        /**
         * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
         */
        {
          pid: 0,
          v1pid: 0,
          lpSymbol: 'SoneFinanceDex',
          lpAddresses: {
            1868: '',
            1946: '0xf2658ee288e92789e6c3f4f33cb1c0eaaef338f6',
          },
          token: serializedTokens.cake,
          quoteToken: serializedTokens.weth,
        },
        {
          pid: 1,
          v1pid: 1,
          lpSymbol: 'SONE-ETH LP',
          lpAddresses: {
            1868: '',
            1946: '0x87E8254eBA8a723E64e71549B79BC65225b05973',
          },
          token: serializedTokens.cake,
          quoteToken: serializedTokens.weth,
        },
        // {
        //   pid: 2,
        //   v1pid: 1,
        //   lpSymbol: 'SONE-ETH LP',
        //   lpAddresses: {
        //     1868: '',
        //     1946: '0x87E8254eBA8a723E64e71549B79BC65225b05973',
        //   },
        //   token: serializedTokens.cake,
        //   quoteToken: serializedTokens.weth,
        // },
        // {
        //   pid: 3,
        //   v1pid: 1,
        //   lpSymbol: 'SONE-ETH LP',
        //   lpAddresses: {
        //     1868: '',
        //     1946: '0x87E8254eBA8a723E64e71549B79BC65225b05973',
        //   },
        //   token: serializedTokens.cake,
        //   quoteToken: serializedTokens.weth,
        // },
        // {
        //   pid: 2,
        //   v1pid: 2,
        //   lpSymbol: 'QS_ETH LP',
        //   lpAddresses: {
        //     1868: '0xdD58B168d467EDe6AD89620355198B2B81305041',
        //     167009: '',
        //   },
        //   token: serializedTokens.cake,
        //   quoteToken: serializedTokens.weth,
        // },
      ]

export default farms
