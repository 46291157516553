import { createSelector } from '@reduxjs/toolkit'

const selectList = (state) => {
  const items = state?.LeaderBoardReducer?.list
  return items
}

const selectUser = (state) => {
  const user = state?.LeaderBoardReducer?.userInfo
  return user
}

export const listData = createSelector([selectList], (data) => {
  return data
})

export const userData = createSelector([selectUser], (data) => {
  return data
})
